import React from 'react';
import {VendingMachineInformation} from "./gen";
import pinGray from './assets/pin_gray.png'
import rightArrow from './assets/right_arrow.png'



interface Props {
    vendingMachine: VendingMachineInformation
    isSelected: boolean
    onClick: Function
}

function VendingMachineListItem(props: Props) {
    return (
        <div className={`container mb-2 border-bottom ${props.isSelected && "backgroundSecondary"}`} onClick={()=>{props.onClick()}}>
            <div className={""}>
                <h5 className={"machineListItemHeader"}>{(props.vendingMachine.description)}</h5>
            </div>
            <div className={"d-flex align-items-center"}>
                <div className={"p-2"}>
                    <img src={pinGray} alt=""/>
                </div>
                <div className={"p-2"}>
                    <span className={"machineListItemAddress"}>{props.vendingMachine.address}</span>
                </div>
                <div className={"p-2 ms-auto"}>
                    <img src={rightArrow} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default VendingMachineListItem;
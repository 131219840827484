import React, { useMemo, useState } from 'react';
import countryList from 'react-select-country-list'
import { Link, Navigate, useNavigate } from "react-router-dom";
import WelcomeHeader from "./welcome_header";
import { IAuthenticationContext, withAuthentication } from "./authentication";
import { grpcAPI } from "./api";
import Input from "react-phone-number-input/input";

interface Props extends IAuthenticationContext {

}

function SignUpPage(props: Props) {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [age, setAge] = useState<number>(0);
    const [gender, setGender] = useState<string>("Hidden");
    const [customisedGender, setCustomisedGender] = useState<string>("");
    const [sexualOrientation, setSexualOrientation] = useState<string>("");
    const [hasTestedHivBefore, setHasTestedHivBefore] = useState<boolean | undefined>(undefined);
    const [isAbove18, setIsAbove18] = useState<boolean | undefined>(undefined);
    const [researchAndReporting, setResearchAndReporting] = useState<boolean>(true);
    const [isAgreePolicyAndAgreement, setIsAgreePolicyAndAgreement] = useState<boolean | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const countryOptions = useMemo(() => countryList().getData().map((option) => { return <option key={option.value} value={option.label}>{option.label}</option> }), [])
    const ageOptions = []
    const navigate = useNavigate()
    for (let i = 18; i < 100; i++) {
        ageOptions.push(<option key={i} value={i}>{i}</option>)
    }

    const handleResearchAndReporting = (e: any) => {
        setResearchAndReporting(e.target.checked)
    }

    const handleCountryChange = (e: any) => {
        setCountry(e.target.value)
    }

    const handleAgeChange = (e: any) => {
        setAge(e.target.value)
    }

    const handleSexualOrientationChange = (e: any) => {
        setSexualOrientation(e.target.value)
    }

    const handleGenderChange = (e: any) => {
        setGender(e.target.value)
    }

    const handleCustomisedGenderChange = (e: any) => {
        setCustomisedGender(e.target.value)
    }

    const handleHasTestedHivBefore = (e: any) => {
        if (e.currentTarget.value === "yes") {
            setHasTestedHivBefore(true)
        }
        if (e.currentTarget.value === "no") {
            setHasTestedHivBefore(false)
        }
    }

    const handleIsAbove18Change = (e: any) => {
        setIsAbove18(e.target.checked)
    }

    const handleIsAgreePolicyAndAgreementChange = (e: any) => {
        setIsAgreePolicyAndAgreement(e.target.checked)
    }

    const signup = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (isLoading) {
            return;
        }
        const apiService = new grpcAPI();
        if (phoneNumber === "") {
            window.alert("Mobile Number can’t be empty")
            return;
        }
        if (country === "") {
            window.alert("Please select Country of Birth")
            return;
        }
        if (age === 0) {
            window.alert("Please select Age")
            return;
        }
        if (sexualOrientation === "") {
            window.alert("Please select Sexual Orientation")
            return;
        }
        if (hasTestedHivBefore === undefined) {
            window.alert("Please select Have you ever tested for HIV before")
            return;
        }
        if (isAbove18 === undefined) {
            window.alert("Please confirm you are over 18")
            return;
        }
        if (isAgreePolicyAndAgreement === undefined) {
            window.alert("Please confirm you have read and agreed privacy policy")
            return;
        }
        // if (gender === ""){
        //     window.alert("Please select Gender")
        //     return;
        // }
        setIsLoading(true);
        apiService.hivTestAPIServiceSignup({
            mobile: phoneNumber,
            countryOfBirth: country,
            yearOfBirth: (parseInt(new Date().getFullYear().toString()) - age).toString(),
            sexOrientation: sexualOrientation,
            testedBefore: hasTestedHivBefore,
            overEighteen: isAbove18,
            agreement: isAgreePolicyAndAgreement,
            gender: gender !== "Other" ? gender : customisedGender
        }).then((response) => {
            if (response.data.success) {
                localStorage.setItem("mobile", phoneNumber ?? "");
                localStorage.setItem("challengeCode", response.data.challengeCode ?? "");
                setIsLoading(false)
                navigate("/verification", { replace: true })
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((reason) => {
            window.alert("Unknown server error, please retry")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    if (props.authentication.authenticated) {
        return (<Navigate to="/" />);
    }

    return (
        <div>
            <WelcomeHeader />
            <div className={"backgroundSecondary py-3"}>
                <div className={"container"}>
                    <div className={"pt-3 footText1"}>
                        <p>
                            Already have an account? <Link className={"textLink textUnderline"} to={"/sign_in"}>Sign in here</Link>
                        </p>
                    </div>
                    <div className="form-floating mb-3">
                        <Input className="form-control border-0 border-bottom phoneInput"
                            international
                            country="AU"
                            value={phoneNumber}
                            onChange={(value) => { setPhoneNumber(String(value)) }} />
                        <label htmlFor="floatingInput" className={"textSecondary"}>Mobile Number</label>
                        <span className="countryCode textSecondary">+61</span>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select border-0 border-bottom" aria-label="Floating" value={country} onChange={handleCountryChange}>
                            <option hidden />
                            {countryOptions}
                        </select>
                        <label htmlFor="floatingSelect" className={"textSecondary"}>Country of Birth</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select border-0 border-bottom" aria-label="Floating" value={age} onChange={handleAgeChange}>
                            <option hidden value={0} />
                            {ageOptions}
                        </select>
                        <label htmlFor="floatingSelect" className={"textSecondary"}>Age</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select border-0 border-bottom" aria-label="Floating" value={gender} onChange={handleGenderChange} hidden={true}>
                            <option hidden />
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                            <option value={"Non-binary"}>Non-binary</option>
                            <option value={"Other"}>I use a different term</option>
                        </select>
                        {gender === "Other" && <input hidden={true} type="text" className="form-control border-0 border-bottom" value={customisedGender} onChange={handleCustomisedGenderChange} />}
                        <label htmlFor="floatingSelect" className={"textSecondary"}>Gender</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select border-0 border-bottom" aria-label="Floating" value={sexualOrientation} onChange={handleSexualOrientationChange}>
                            <option hidden />
                            <option value={"Gay"}>Gay</option>
                            <option value={"Lesbian"}>Lesbian</option>
                            <option value={"Heterosexual"}>Heterosexual</option>
                            <option value={"Bisexual"}>Bisexual</option>
                            <option value={"Pansexual"}>Pansexual</option>
                            <option value={"Asexual"}>Asexual</option>
                        </select>
                        <label htmlFor="floatingSelect" className={"textSecondary"}>Sexual Orientation</label>
                    </div>
                    <div>
                        <label className={"textSecondary"} >Have you ever tested for HIV before?</label>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={"yes"} checked={hasTestedHivBefore === true} onChange={handleHasTestedHivBefore} />
                            <label className="form-check-label textSecondary" htmlFor="flexRadioDefault1">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={"no"} checked={hasTestedHivBefore === false} onChange={handleHasTestedHivBefore} />
                            <label className="form-check-label textSecondary" htmlFor="flexRadioDefault2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"backgroundPrimary py-4"}>
                <div className={"container"}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={isAbove18} onChange={handleIsAbove18Change} />
                        <label className="form-check-label textPrimary" htmlFor="flexCheckDefault">
                            I am over 18
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="flexCheckAgree" checked={researchAndReporting} onChange={handleResearchAndReporting} />
                        <label className="form-check-label textPrimary" htmlFor="flexCheckAgree">
                            I agree to my de-identified data being used for research and reporting purposes including publication.
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isAgreePolicyAndAgreement} onChange={handleIsAgreePolicyAndAgreementChange} />
                        <label className="form-check-label textPrimary" htmlFor="flexCheckChecked">
                            I have read and agreed <a className={"textLink"} href={"https://samesh.org.au/privacy-policy"}>privacy policy</a>.
                        </label>
                    </div>
                    <div className={"container pt-3 text-center"}>
                        <div className="d-grid mw-100 buttonPrimary rounded">
                            <button className="textButton btn" onClick={signup}>
                                {!isLoading && <span>Proceed</span>}
                                {isLoading && <i className="fas fa-spinner fa-spin" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withAuthentication(SignUpPage);

import React, {useState} from 'react';
import {BottomSheet} from "react-spring-bottom-sheet";
import "./bottom_sheet_style.css";
import PinInput from "react-pin-input";

interface Props {
    open:boolean
    onDismiss:Function,
    onSearchVendingMachine:Function
    isLoading:boolean
}

function SearchMachineBottomSheet(props:Props) {
    const [machineCode, setMachineCode] = useState<string>("");

    const onMachineCodeChange = (value:string, index:number)=>{
        setMachineCode(value)
    }


    return (
        <div>
            <BottomSheet
                open={props.open}
                onDismiss={()=>{props.onDismiss()}}
            >
                <div className={"container text-center"}>
                    <div className={"pt-3"}>
                        <h1 className={"bottomSheetHeader"}>Please input the machine no.</h1>
                    </div>
                    <div className={"pt-3"}>
                        <PinInput
                            length={4}
                            initialValue={machineCode}
                            secret ={false}
                            onChange={onMachineCodeChange}
                            type={"numeric"}
                            inputMode="number"
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                            inputStyle={
                                {
                                    background: "#FFFFFF",
                                    border: "1px solid #DDDDDD",
                                    boxSizing: "border-box",
                                    borderRadius: "8px",
                                }
                            }
                            inputFocusStyle={
                                {
                                    background: "#FFFFFF",
                                    border: "1px solid #DDDDDD",
                                    boxSizing: "border-box",
                                    borderRadius: "8px"

                                }
                            }
                            onComplete={(value, index) => {}}
                            autoSelect={true}
                            regexCriteria={/^[0-9]*$/}
                        />
                    </div>
                    <div className={"pt-3 pb-5"}>
                        <div className={"pt-3 text-center"}>
                            <div className="d-grid mw-100 buttonPrimary rounded">
                                <button className="textButton btn" onClick={()=>{props.onSearchVendingMachine(machineCode)}}>
                                    {!props.isLoading && <span>OK</span>}
                                    {props.isLoading && <i className="fas fa-spinner fa-spin"/>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
        </div>
    );
}

export default SearchMachineBottomSheet;
import React from 'react';
import connect from "./assets/connect.png";

function WelcomeHeader() {
    return (
        <div className={"backgroundPrimary text-center pb-3"}>
            <div className={"container"}>
                <div className={"py-4"}>
                    <img className={"rounded-top rounded-bottom mw-100 mx-auto"} src={connect} alt=""/>
                </div>
                <p className="text-start textPrimary">Great! You are on your way to getting your free HIV self-test kit!</p>
                <p className="text-start textPrimary">We need to ask you a few questions before you can get started.</p>
                <p className="text-start textPrimary">We will need to grab your mobile number so you can access the test kit.</p>
                <p className="text-start textPrimary">All your information is kept confidential and we do not share this with anyone.</p>
            </div>
        </div>
    );
}

export default WelcomeHeader;
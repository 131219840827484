import React, {Fragment, useEffect} from 'react';
import TextButton from "./text_button";
import {useNavigate, useParams} from "react-router-dom";
import SameshLogo from "./samesh_logo";
import ThoneHarbourLogo from "./thorne_harbour_logo";
import {grpcAPI} from "./api";

function DispenseSuccessPage() {
    const navigate = useNavigate()
    const params = useParams();

    const queryDispenseStatus = ()=>{
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceQueryDispenseStatus({
            dispenseId:params.id
        }).then((response)=>{
            if (response.data.inProgress){

            } else {
                if (response.data.success){
                } else {
                    navigate(`/`, {replace: true})
                }
            }
        })
    }

       // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(queryDispenseStatus, [params.id])

    return (
        <Fragment>
            <div className={""}>
                <div className={"container pt-5"}>
                    <h1 className="text-start successHeader ">If you want to find out more about HIV self-testing, the CONNECT project, or SAMESH, go to the CONNECT website: <a href="https://samesh.org.au/connect-free-hiv-test-kits/" className={"textLink"}>hivconnect.org.au</a></h1>
                </div>
                <div className={"container pt-3 text-center"}>
                    <div className={"py-2"}>
                        <TextButton text={"If you need another test kit, please scan the QR code or input the machine no. again"} onClick={()=>{navigate("/")}}/>
                    </div>
                </div>
            </div>
            <div className="mt-auto py-3">
                <SameshLogo/>
                <ThoneHarbourLogo />
            </div>
        </Fragment>
    );
}

export default DispenseSuccessPage;

import React from 'react';
import connect from "./assets/connect.png";
import {useLocation, matchPath} from "react-router-dom";


function Footer() {
    const location = useLocation();
    const isMtAuto = !matchPath("/machines", location.pathname) && !matchPath("/dispense/:id/success", location.pathname)

    return (
        <footer className={`footer py-3 backgroundSecondary ${isMtAuto && "mt-auto"}`}>
            <div className="container text-center">
                <div className={"row"}>
                    <div className={"col-6"}>
                        <img className={"rounded rounded mw-100 float-end"} src={connect} alt=""/>
                    </div>
                    <div className={"col-6 d-flex align-items-center"}>
                        <div>
                            <span className={"footText1"}>SAMESH CONNECT</span>
                            <br/>
                            <a className={"footText2"} href="tel:+610870995300">+61 (8) 7099 5300</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
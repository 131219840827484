import './App.scss';
import {Route, Routes} from "react-router-dom";
import SignUpPage from "./sign_up_page";
import Footer from "./footer";
import VerificationPage from "./verification_page";
import SignInPage from "./sign_in_page";
import DashboardPage from "./dashboard_page";
import {useEffect, useState} from "react";
import {AuthenticationContext} from "./authentication";
import {AuthenticationProtected} from "./authentication_protected";
import MachinePage from "./machine_page";
import DispenseSuccessPage from "./dispense_success_page";
import AllVendingMachinesPage from "./all_vending_machines_page";
import CoolingOffPage from "./cooling_off_page";

function App() {
    const [authentication, setAuthentication] = useState({
        authenticated:null,
        mobile:null,
        authentication_token:null
    });

    const login = (token, mobile)=>{
        localStorage.setItem("mobile", mobile);
        localStorage.setItem("authToken", token);

        reloadMyInfo(token, mobile)
    }

    const reloadMyInfo = (token, mobile)=>{
        // FIXME: We need to check whether the saved token is valid with the backend
        if (token != null){
            setAuthentication({
                authenticated: true,
                mobile: mobile,
                authorization_token: token
            })
        } else {
            setAuthentication({
                authenticated: false,
                mobile: "",
                authorization_token: ""
            })
            localStorage.removeItem("mobile");
            localStorage.removeItem("token");
        }
    }

    const init = () => {
        let mobile = localStorage.getItem("mobile");
        let token = localStorage.getItem("authToken");
        if (mobile != null && token != null){
            login(token, mobile)
        } else {
            setAuthentication({
                authenticated:false,
                mobile:null,
                authentication_token:null
            })
        }
    }

	// eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [])


  return (
      <div className={"d-flex flex-column sticky-footer-wrapper min-vh-100 backgroundPrimary"}>
          <AuthenticationContext.Provider value={{
              authentication:authentication,
              logout:()=>{},
              login:login
          }}>
              <Routes>
                  <Route exact path="sign_in" element={<SignInPage/>}/>
                  <Route exact path="sign_up" element={<SignUpPage/>}/>
                  <Route exact path="verification" element={<VerificationPage/>}/>
                  <Route exact path="/" element={<AuthenticationProtected><DashboardPage/></AuthenticationProtected>}/>
                  <Route exact path="machines" element={<AuthenticationProtected><AllVendingMachinesPage/></AuthenticationProtected>}/>
                  <Route exact path="machines/:vmId" element={<AuthenticationProtected><MachinePage/></AuthenticationProtected>}/>
                  <Route exact path="dispense/:id/success" element={<AuthenticationProtected><DispenseSuccessPage/></AuthenticationProtected>}/>
                  <Route exact path="cooling_off" element={<AuthenticationProtected><CoolingOffPage/></AuthenticationProtected>}/>
              </Routes>
          </AuthenticationContext.Provider>
          <Footer/>
      </div>
  );
}

export default App;

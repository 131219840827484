import React, {useState} from 'react';
import WelcomeHeader from "./welcome_header";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {grpcAPI} from "./api";
import {IAuthenticationContext, withAuthentication} from "./authentication";
import Input from 'react-phone-number-input/input'



interface Props extends IAuthenticationContext {

}

function SignInPage(props:Props) {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate()

    const signIn = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        if (isLoading){
            return;
        }
        const apiService = new grpcAPI();
        if (phoneNumber === ""){
            window.alert("Mobile Number can’t be empty")
            return;
        }
        setIsLoading(true);
        apiService.hivTestAPIServiceSignin({
            mobile:phoneNumber
        }).then((response)=>{
            if (response.data.success){
                localStorage.setItem("mobile", phoneNumber ?? "");
                localStorage.setItem("challengeCode", response.data.challengeCode ?? "");
                setIsLoading(false)
                navigate("/verification", {replace: true})
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((reason)=>{
            window.alert("Unknown server error, please retry");
        }).finally(()=>{
            setIsLoading(false)
        })
    }

    if (props.authentication.authenticated) {
        return (<Navigate to="/"/>);
    }

    return (
        <div>
            <WelcomeHeader/>
            <div className={"container py-3 backgroundSecondary"}>
                <div className={"footText1"}>
                    <p>
                        Don’t have an account? <Link className={"textLink textUnderline"} to={"/sign_up"}>Sign up for free</Link>
                    </p>
                </div>
                <div className="form-floating mb-3">
                    <Input className="form-control border-0 border-bottom phoneInput"
                        international
                        country="AU"
                        value={phoneNumber}
                        onChange={(value)=>{setPhoneNumber(String(value))}}/>
                    <label htmlFor="floatingInput" className={"textSecondary"}>Mobile Number</label>
                    <span className="countryCode textSecondary">+61</span>
                </div>
            </div>
            <div className={"backgroundPrimary py-4"}>
                <div className={"container"}>
                    <div className={"container pt-3 text-center"}>
                        <div className="d-grid mw-100 buttonPrimary rounded">
                            <button className="textButton btn" onClick={signIn}>
                                {!isLoading && <span>Sign In</span>}
                                {isLoading && <i className="fas fa-spinner fa-spin"/>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withAuthentication(SignInPage);
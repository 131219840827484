import React from 'react';
import logo from "./assets/thorne_harbour_logo.png";

function ThorneHarbourLogo() {
    return (
        <div className={"container pt-4 text-center"}>
            <div className={"card"} style={{height:"120px"}}>
                <img src={logo} alt="" className={"mw-100 mx-auto my-3"}/>
            </div>
        </div>
    );
}

export default ThorneHarbourLogo;
import React from 'react';

export interface Authentication {
    authenticated: boolean | null; // null means authentication is loading
    mobile?: string;
    authentication_token?: string;
}

export interface IAuthenticationContext {
    authentication: Authentication;

    login(token: string, mobile: string): void;

    logout(): void;
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenticated: null,
        },
        logout: () => {
        },
        login: (t, e): Promise<any> => {
            return new Promise<any>(() => {
            })
        },
    }
);

function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) =>
                    <C {...props} authentication={authentication} login={login} logout={logout}/>}
            </AuthenticationContext.Consumer>
        );
    }
}

export {AuthenticationContext, withAuthentication}
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import PinInput from 'react-pin-input';
import {useNavigate, Navigate} from "react-router-dom";
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {grpcAPI} from "./api";
import {formatPhoneNumberIntl} from "react-phone-number-input";


interface Props extends IAuthenticationContext {

}

function VerificationPage(props:Props) {
    const [challengeCode, setChallengeCode] = useState<string>(localStorage.getItem("challengeCode") ?? "");
    const [mobile] = useState<string>(localStorage.getItem("mobile") ?? "");
    const [otp, setOtp] = useState<string>("");
    const [isResending, setIsResending] = useState<boolean>(false);
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const navigate = useNavigate()

    const onChallengeCodeChange = (value:string, index:number)=>{
        setOtp(value)
    }

    const verify = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        if (isVerifying){
            return;
        }
        const apiService = new grpcAPI();
        if (otp === ""){
            window.alert("Auth code can’t be empty")
            return;
        }
        setIsVerifying(true);
        apiService.hivTestAPIServiceVerifyAuthCode({
            mobile: mobile,
            challengeCode: challengeCode,
            authCode: otp
        }).then((response)=>{
            if (response.data.loginSuccess){
                localStorage.removeItem("challengeCode")
                localStorage.setItem("authToken", response.data.authToken ?? "")
                setIsVerifying(false);
                props.login(localStorage.getItem("authToken") ?? "", localStorage.getItem("mobile") ?? "")
                navigate("/", {replace: true})
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((reason)=>{
            window.alert("Unknown server error, please retry")
        }).finally(()=>{
            setIsVerifying(false);
        })
    }

    const signIn = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        if (isResending){
            return;
        }
        setIsResending(true);
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceSignin({
            mobile:mobile
        }).then((response)=>{
            if (response.data.success){
                localStorage.setItem("mobile", mobile ?? "");
                localStorage.setItem("challengeCode", response.data.challengeCode ?? "");
                setChallengeCode(response.data.challengeCode ?? "")
            } else {
                window.alert(response.data.errorMessage)
            }
        }).catch((reason) => {
            window.alert("Unknown server error, please retry");
        }).finally(()=>{
            setIsResending(false);
        })
    }

    if (props.authentication.authenticated) {
        return (<Navigate to="/"/>);
    }

    const formatted =  formatPhoneNumberIntl(mobile);
    return (
        <div>
            <div className={"pt-5 backgroundSecondary"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <Link to={"/sign_up"}>
                                <p className={"text-start"}><i className="fas fa-arrow-left textLink"/></p>
                            </Link>
                        </div>
                        <div className={"col"}>
                            <p className={"textLink text-end textUnderline"}>Need Help?</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"backgroundPrimary py-3"}>
                <div className={"container"}>
                    <div>
                        <h5 className="text-start pageHeader">Mobile Number Verification</h5>
                        <p className={"text-start textSecondary"}>
                            Please enter the 4-digit code we sent to your mobile <span className={"textPrimary"}>{formatted}</span>
                        </p>
                    </div>
                    <div>
                        <PinInput
                            length={4}
                            initialValue={otp}
                            secret ={false}
                            onChange={onChallengeCodeChange}
                            type="numeric"
                            inputMode="number"
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                            inputStyle={
                                {
                                    background: "#FFFFFF",
                                    border: "1px solid #DDDDDD",
                                    boxSizing: "border-box",
                                    borderRadius: "8px",
                                }
                            }
                            inputFocusStyle={
                                {
                                    background: "#FFFFFF",
                                    border: "1px solid #DDDDDD",
                                    boxSizing: "border-box",
                                    borderRadius: "8px"

                                }
                            }
                            onComplete={(value, index) => {}}
                            autoSelect={true}
                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                    </div>
                    <div className={"text-center pt-3"}>
                        <button type="button" className="btn" onClick={signIn}>
                            {!isResending && <p className={"textLink textUnderline"}>
                                Resend Code
                            </p>}
                            {isResending && <i className="fas fa-spinner fa-spin"/>}
                        </button>
                    </div>
                    <div className={"container pt-3 text-center"}>
                        <div className="d-grid mw-100 buttonPrimary rounded">
                            <button className="textButton btn" onClick={verify}>
                                {!isVerifying && <span>Verify</span>}
                                {isVerifying && <i className="fas fa-spinner fa-spin"/>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withAuthentication(VerificationPage);
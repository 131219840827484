import React, {useEffect, useState} from 'react';
import TextButton from "./text_button";
import qrcode from './assets/qrcode.png'
import machine from './assets/machine.png'
import SearchMachineBottomSheet from "./search_machine_bottom_sheet";
import {Link, useNavigate} from "react-router-dom";
import {grpcAPI} from "./api";


function DashboardPage() {
    const [open, setOpen] = useState(false)
    const [isFindingVendingMachine, setIsFindingVendingMachine] = useState(false)
    const navigate = useNavigate()
    const findVendingMachine = (machineCode:string)=>{
        setIsFindingVendingMachine(true);
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceFindVendingMachine({
            machineCode:machineCode
        }).then((response)=>{
            if (response.data.found){
                setIsFindingVendingMachine(false);
                navigate(`/machines/${response.data.vendingMachineId}`, {replace: true})
            } else {
                window.alert("Vending Machine does not exist.")
            }
        }).catch((result)=>{
            window.alert("Unknown server error, please retry")
        }).finally(()=>{
            setIsFindingVendingMachine(false);
        })
    }

    const queryCustomerEligibilityStatus = () => {
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceCheckUserEligibility({}).then((response)=>{
            if (!response.data.eligible){
                navigate(`/cooling_off`, {replace: true})
            }
        })
    }

    useEffect(queryCustomerEligibilityStatus, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <SearchMachineBottomSheet open={open} onDismiss={()=>{setOpen(false)}} onSearchVendingMachine={findVendingMachine} isLoading={isFindingVendingMachine}/>
            <div className={"backgroundPrimary pt-5 pb-3"}>
                <div className={"container"}>
                    <h1 className="text-start dashboardHeader ">You are one step closer to getting your kit.</h1>
                </div>
            </div>
            <div className={"container pt-4 text-center backgroundSecondary"}>
                <div>
                    <h1 className="text-start dashboardHeader ">Are you in front of the machine now?</h1>
                </div>
                <div>
                    <p className="text-start textPrimary dashboardText">If you are in front of the machine, either:</p>
                </div>
                <div className={"py-2"}>
                    <TextButton text={"Scan the QR code on the machine with your phone, or"} onClick={()=>{}}/>
                </div>
                <div className={"py-2"}>
                    <TextButton text={"Enter the machine no. into the pop up box"} onClick={()=>{setOpen(true)}}/>
                </div>
            </div>
            <div className={"backgroundPrimary py-3"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <img className={"rounded rounded mw-100 float-end"} src={machine} alt=""/>
                        </div>
                        <div className={"col-6"}>
                            <img className={"rounded rounded mw-100 float-start"} src={qrcode} alt=""/>
                        </div>

                    </div>
                    <div className={"pt-3"}>
                        <p className={"text-start textPrimary dashboardText"}>
                            If you are not at the vending machine, see a list of vending machines. <Link
                            to={"/machines"} className={"textLink textUnderline"}>See list here</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;

import React from 'react';
import GoogleMapReact from 'google-map-react';
import {VendingMachineInformation} from "./gen";
import VendingMachineMarker from "./vending_machine_marker";

interface Props {
    vendingMachines:VendingMachineInformation[]
    selectedVendingMachine:VendingMachineInformation|null
    onSelectVendingMachine:Function
}

function VendingMachineMap(props:Props) {
    // @ts-ignore
    let google = window.google;
    let bounds = new google.maps.LatLngBounds();
    let markers : any[]= [];

    props.vendingMachines.forEach((vendingMachine, index)=>{
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(vendingMachine.latitude,vendingMachine.longitude),
        });
        bounds.extend(marker.getPosition());
        markers.push(<VendingMachineMarker key={index} lat={vendingMachine.latitude ?? 0} lng={vendingMachine.longitude ?? 0} vendingMachine={vendingMachine} isSelected={props.selectedVendingMachine === vendingMachine} onSelectVendingMachine={props.onSelectVendingMachine}/>)
    })

    let center = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng()
    };
    let zoom = 10;

    if (props.selectedVendingMachine !== null){
        center = {
            lat: props.selectedVendingMachine.latitude,
            lng: props.selectedVendingMachine.longitude
        }
        zoom = 15;
    }

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key:"AIzaSyAiK6esuGcx32taWwFdMdQBGlNF0eNQkhs" }}
            zoom={zoom}
            center={center}
        >
            {markers}
        </GoogleMapReact>
    );
}

export default VendingMachineMap;
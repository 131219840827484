import React from 'react';

interface Props {
    text:string
    onClick:Function,
}


function TextButton(props:Props) {
    return (
        <div onClick={()=>{props.onClick()}}>
            <div className={"d-flex align-items-center boxSelection"}>
                <div className={"container align-bottom"}>
                    <p className="card-text text-start boxSelectionText">{props.text}</p>
                </div>
            </div>
        </div>
    );
}

export default TextButton;
import React from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Navigate, matchPath, useLocation} from "react-router-dom";

interface Props extends IAuthenticationContext {
    children:any
}

function _AuthenticationProtected(props: Props) {
    const location = useLocation();
    const isQrCodeScanRoute = matchPath("/machines/:vmId", location.pathname)

    console.log(location.pathname)

    if (props.authentication.authenticated === null) {
            return (<div/>); // TODO: it should be a spinner
    }

    if (!props.authentication.authenticated) {
        if (isQrCodeScanRoute){
            window.location.replace("https://samesh.org.au/connect-free-hiv-test-kits/");
            return (<div/>)
        }
        return (<Navigate to="/sign_in"/>);
    }

    if (props.authentication.authenticated) {
            return props.children
    }
}

const AuthenticationProtected = withAuthentication(_AuthenticationProtected);
export {AuthenticationProtected};

import React, {useEffect, useState} from 'react';
import {grpcAPI} from "./api";
import {VendingMachineInformation} from "./gen";
import {useNavigate} from "react-router-dom";

function MachinePage() {
    const [vendingMachine, setVendingMachine] = useState<VendingMachineInformation>();
    const [vmId, setVmId] = useState<string>();
    const [dispenseInProgress, setDispenseInProgress] = useState<boolean>(false);
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);

    const findVendingMachine = () => {
        setLoading(true);

        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceCheckUserEligibility({}).then((response) => {
            if (!response.data.eligible) {
                navigate(`/cooling_off`, {replace: true})
                return;
            }
        })

        apiService.hivTestAPIServiceFindVendingMachine({
            machineQrcode: window.location.href
        }).then((response) => {
            if (response.data.found) {
                setVendingMachine(response.data.vendingMachine)
                setVmId(response.data.vendingMachineId)
            } else {
                alert("Vending Machine does not exist")
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(findVendingMachine, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const startDispense = ()=>{
        if (dispenseInProgress){
            return;
        }
        setDispenseInProgress(true);
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceDispenseTestKit({
            vendingMachineId:vmId
        }).then((response)=>{
          if (response.data.success){
              queryDispenseStatus(response.data.dispenseId ?? "")
          } else {
              setDispenseInProgress(false);
              alert("Unable to dispense, please try again later")
          }
        }).catch((result)=>{
            setDispenseInProgress(false);
        })
    }

    const queryDispenseStatus = (dispenseId:string)=>{
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceQueryDispenseStatus({
            dispenseId:dispenseId
        }).then((response)=>{
            if (response.data.inProgress){
                setDispenseInProgress(true);
                window.setTimeout(()=>{queryDispenseStatus(dispenseId)}, 1000)
            } else {
                setDispenseInProgress(false);
                if (response.data.success){
                    navigate(`/dispense/${dispenseId}/success`, {replace: true})
                } else {
                    window.alert(response.data.errorMessage)
                    setDispenseInProgress(false);
                }
            }
        }).catch((result)=>{
            window.alert("Unknown server error, please retry")
            setDispenseInProgress(false);
        })
    }

    return (
        <div className={"container"}>
            <div className={"mt-5 backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-3"}>
                    <img src={vendingMachine?.photoUrl} alt="" className={"mw-100"}/>
                </div>
                <div className={"container text-center pb-3"}>
                    <h5 className={"machineName"}>
                        {vendingMachine?.description}
                    </h5>
                    <p className={"machineAddress"}>{vendingMachine?.address}</p>
                </div>
            </div>

            <div className={"pt-3 text-center"} style={{paddingBottom: "10px"}}>
                <div className="d-grid mw-100 buttonPrimary rounded" style={{minHeight: "50px"}}>
                    {!loading &&
                        <button className="textButton btn" onClick={startDispense}>
                            {!dispenseInProgress && <div className={"row align-items-center"}>
                                <div className={"col-10"}>
                                    <div className={"text-start buttonText"}>I’m here, please dispense now</div>
                                </div>
                                <div className={"col-2"}>
                                    <i className="fas fa-arrow-right text-end"/>
                                </div>
                            </div>}
                            {dispenseInProgress && <i className="fas fa-spinner fa-spin"/>}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default MachinePage;

import React, {Fragment, useEffect, useState} from 'react';
import VendingMachineMap from "./vending_machine_map";
import {grpcAPI} from "./api";
import {VendingMachineInformation} from "./gen";
import VendingMachineListItem from "./vending_machine_list_item";
import TextButton from "./text_button";
import {useNavigate} from "react-router-dom";
import SearchMachineBottomSheet from "./search_machine_bottom_sheet";

function AllVendingMachinesPage() {
    const [vendingMachines, setVendingMachines] = useState<VendingMachineInformation[]>([])
    const [open, setOpen] = useState(false)
    const [isFindingVendingMachine, setIsFindingVendingMachine] = useState(false)
    const navigate = useNavigate()
    const findVendingMachine = (machineCode:string)=>{
        setIsFindingVendingMachine(true);
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceFindVendingMachine({
            machineCode:machineCode
        }).then((response)=>{
            if (response.data.found){
                setIsFindingVendingMachine(false);
                navigate(`/machines/${response.data.vendingMachineId}`, {replace: true})
            } else {
                window.alert("Vending Machine does not exist.")
            }
        }).catch((result)=>{
            window.alert("Unknown server error, please retry")
        }).finally(()=>{
            setIsFindingVendingMachine(false);
        })
    }
    const [selectedVendingMachine, setSelectedVendingMachine] = useState<VendingMachineInformation | null>(null)


    const getAllVendingMachine = ()=>{
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceCheckUserEligibility({}).then((response)=>{
            if (!response.data.eligible){
                navigate(`/cooling_off`, {replace: true})
                return;
            }
        })
        apiService.hivTestAPIServiceListVendingMachines().then((response) => {
            setVendingMachines(response.data.vendingMachines ?? [])
        });
    }

    useEffect(getAllVendingMachine, []) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleSelectVendingMachine = (vendingMachine: VendingMachineInformation)=>{
        if (vendingMachine === selectedVendingMachine){
            setSelectedVendingMachine(null)
        } else {
            setSelectedVendingMachine(vendingMachine)
        }
    }

    const vendingMachineListItems = vendingMachines.map((vendingMachine, index) => {
        return <VendingMachineListItem key={index}
                                       vendingMachine={vendingMachine}
                                       onClick={()=>{toggleSelectVendingMachine(vendingMachine)}}
                                       isSelected={selectedVendingMachine === vendingMachine}/>
    })


    return (
        <Fragment>
            <SearchMachineBottomSheet open={open} onDismiss={()=>{setOpen(false)}} onSearchVendingMachine={findVendingMachine} isLoading={isFindingVendingMachine}/>
            <div className={"mb-auto"}>
                <div style={{height:"300px", width: '100%' }}>
                    <VendingMachineMap vendingMachines={vendingMachines} selectedVendingMachine={selectedVendingMachine} onSelectVendingMachine={toggleSelectVendingMachine}/>
                </div>
                <div className={"container pt-3"}>
                    {vendingMachineListItems}
                </div>
            </div>
            <div className="backgroundSecondary border-bottom mt-3">
                <div className={"container"}>
                    <div className={"pt-3 text-center"}>
                        <span className={"dashboardText"}>
                        Once you are in front of the machine, either:
                        </span>
                    </div>
                    <div className={"pt-3"}>
                        <TextButton text={"Scan the QR code on the machine with your phone, or"} onClick={()=>{}}/>
                    </div>
                    <div className={"pt-3 pb-3"}>
                        <TextButton text={"Enter the machine no. into the pop up box"} onClick={()=>{setOpen(true)}}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AllVendingMachinesPage;

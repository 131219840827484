import React from 'react';
import pin from './assets/pin.png'
import {VendingMachineInformation} from "./gen";
import {Properties} from "csstype";




interface Props{
    lat:number,
    lng:number,
    vendingMachine:VendingMachineInformation
    isSelected:boolean
    onSelectVendingMachine:Function
}

function VendingMachineMarker(props:Props) {
    let style:Properties<string | number> = {position:"absolute",transform:'translate(-50%, -90%)'};
    if (props.isSelected){
        style = {position:"absolute",transform:'translate(-5%, -98%)'};
    }
    return (
        <div style={style}>
            {
                props.isSelected && <div className={"card"} style={{width: "10rem", height:"12rem"}}>
                    <div className="card-body text-center">
                        <h5 className="card-title machineListItemHeader">{props.vendingMachine.description}</h5>
                        <img src={props.vendingMachine.photoUrl} style={{maxWidth:"40%", maxHeight:"10rem"}} className="card-img-top" alt="..."/>
                        <p className="card-text">{props.vendingMachine.address}</p>
                    </div>
                </div>
            }
            <img src={pin} alt="" onClick={()=>{props.onSelectVendingMachine(props.vendingMachine)}}/>
        </div>
    );
}

export default VendingMachineMarker;
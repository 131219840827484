import React, {useEffect, useState} from 'react';
import {grpcAPI} from "./api";
import {useNavigate} from "react-router-dom";
import moment from "moment";

function CoolingOffPage() {
    const navigate = useNavigate()
    const [blockUntil, setBlockUntil] = useState<number>(0);
    const checkUserEligibility = ()=>{
        const apiService = new grpcAPI();
        apiService.hivTestAPIServiceCheckUserEligibility({}).then((response)=>{
            if (response.data.eligible){
                navigate(`/`, {replace: true})
            } else {
                setBlockUntil(response.data.coolingOffUntilEpoch ?? 0)
            }
        }).catch((reason)=>{
            window.alert("Unknown server error, please retry")
        })
    }

    useEffect(checkUserEligibility, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={"justify-content-center mt-auto"}>
            <div>
                <p className={"coolingOffText"}>
                    Cooling Off...
                </p>
                <p className={"coolingOffText"}>
                    {blockUntil !== 0 && moment.duration({"milliseconds": moment.unix(blockUntil ?? 0).diff(moment.utc())}).humanize()}
                </p>
            </div>
        </div>
    );
}

export default CoolingOffPage;